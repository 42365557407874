<template>
  <!--====== APPIE FAQ PART START ======-->
    
    <section class="appie-faq-area pb-95">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">{{title}}</h3>
                        <p>{{description}}</p>
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion" @click.prevent="OpenQuestion(1)" :class="[showQuestion === 1?'active':'']">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>How long does account verification take?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 1?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Provided the required documentation is submitted, your account should be validated in under an hour during working hours</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion  " :class="[showQuestion === 2?'active':'']" @click.prevent="OpenQuestion(2)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>What are tier 1 transaction limits?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 2?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Up to $500 per transaction; $500 per day; $1,000 per 15 days; $1,000 per 30 days; $3,000 per 6 months</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp animated fadeIn faq-accrodion wow" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion " :class="[showQuestion === 3?'active':'']" @click.prevent="OpenQuestion(3)">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>What are tier 2 transaction limits?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 3?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Tier 2 Customers: Up to $1000 per transaction; $2,999 per day; $2,999 per 15 days; $5,000 per 30 days; $9,999 per 6 months</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                            <div class="accrodion" :class="[showQuestion ===4?'active':'']" @click.prevent="OpenQuestion(4)">
                                <div class="accrodion-inner">
                                    
                                    <div class="accrodion-title">
                                        <h4>What are tier 3 transaction limits?</h4>
                                    </div>
                                    <div class="accrodion-content" :style="[showQuestion === 4?{'display':'block'}:{'display':'none'}]">
                                        <div class="inner">
                                            <p>Tier 3 Customers: Up to $9,999.99 per transaction; $25,000 per day; $50,000 per 6 months</p>
                                        </div><!-- /.inner -->
                                    </div>
                                </div><!-- /.accrodion-inner -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="faq-text text-center pt-40">
                        <p>Can't find an answer? <a href="https://anymoney.tawk.help">Contact our helpdesk</a></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE FAQ PART ENDS ======-->
</template>

<script>
export default {
    props:{
        title:{
            type:String,
        },
        description:{
            type:String,
        }
    },
   data(){
       return {
           showQuestion:1,
       }
   },
    methods: {
        OpenQuestion(value){
            this.showQuestion = value
        }
    }

}
</script>

<style>

</style>