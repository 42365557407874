<template>
  
      <!--====== APPIE SERVICES PART START ======-->
    
    <section class="appie-service-area appie-service-3-area pt-195 pb-100" id="service">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">Fintech Excellence</h3>
                        <p>We are building the most cost-effective integrations and partnerships to provide secure, fast and easy-to-use financial solutions across Africa and its diaspora</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/1.png" alt="">
                        </div>
                        <h4 class="appie-title">Remittances</h4>
                        <p>Cross-border payment to friends and family from 35+ US states to Cameroon, Nigeria, Ivory Coast and more to come</p>
                        <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/2.png" alt="">
                        </div>
                        <h4 class="appie-title">Collections</h4>
                        <p>Seamless funds collection solution for e-Commerce and application developers. Receive payments from your customers via mobile and card payment options</p>
                        <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/3.png" alt="">
                        </div>
                        <h4 class="appie-title">Disbursement</h4>
                        <p>Mass pay your partners, employees and other beneficiaries from a diverse list of payment options. Payments made to mobile wallets and bank accounts seamlessly</p>
                        <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                        <div class="icon">
                            <img src="@/assets/images/icon/4.png" alt="">
                        </div>
                        <h4 class="appie-title">Virtual Cards</h4>
                        <p>Instantly create prepaid cards to be used for online purchases, thereby shielding your actual cards from risks. Reload your cards from your mobile wallets easily</p>
                        <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>