<template>
    <!-- <about-us/> -->
    <about-two/>
</template>

<script>
// import AboutUs from "../components/AboutUs/index"
import AboutTwo from "../components/AboutUsTwo/index.vue";
export default {
    components: {
        // AboutUs,
        AboutTwo
    }
}
</script>

<style>

</style>