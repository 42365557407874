<template>
    <!--====== APPIE HERO PART START ======-->
    
    <section class="appie-hero-area appie-hero-8-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="appie-hero-content appie-hero-content-8">                        
                        <h1 class="appie-title">AnyMoney Remittances</h1>
                        <!-- <h2 class="appie-subtitle">Remittances</h2> -->
                        <p>Most affordable, secure and friendly application for all your remittances from the USA to Africa. Your first transaction is on us. Available on Web, Mobile & PC</p>
                        <ul>
                            <li><a class="item-2" href="https://play.google.com/store/search?q=anymoney&c=apps" target="_blank"><i class="fab fa-google-play"></i> Android</a></li>
                            <li><a class="item-2" href="https://apps.apple.com/app/apple-store/id1659219019?pt=123927420&ct=website&mt=8" target="_blank"><i class="fab fa-apple"></i> iOS</a></li>
                            <li><a class="item-2" href="https://app.anymoney.co"><i class="fab fa-windows"></i> Web</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-hero-thumb-6">
                        <div class="thumb text-center wow animated fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                            <img src="@/assets/images/undraw_happy_announcement.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-8-shape-1">
            <img src="@/assets/images/shape/3.png" alt="">
        </div>
        <div class="home-8-shape-2">
            <img src="@/assets/images/shape/4.png" alt="">
        </div>
        <div class="home-8-shape-3">
            <img src="@/assets/images/shape/1.png" alt="">
        </div>
        <div class="home-8-shape-4">
            <img src="@/assets/images/shape/2.png" alt="">
        </div>
    </section>
    
    <!--====== APPIE HERO PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>