<template>
  <div>
    <sidebar-home-one
      :sidebar="sidebar"
      @toggleSidebar="toggleSidebar"
      :menuItems="navs"
    />
    <header-home-eight @toggleSidebar="toggleSidebar" :menuItems="navs" />

    <!-- <div class="appie-page-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="appie-page-title-item">
              <h3 class="title">About Us</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <a href="/about-two">About us</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <services-home-three />
    <!-- <funfact-home-three />
    <work-home-eight class="pt-90" /> -->
    <!-- <testimonial-home-eight /> -->
    <!-- <sponser-home-eight class="appie-signup-area" /> -->
    <!-- <footer-home-one class="appie-footer-about-area" /> -->
    <footer-home-eight />
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
// import SponserHomeEight from "../HomeEight/SponserHomeEight.vue";
// import TestimonialHomeEight from "../HomeEight/TestimonialHomeEight.vue";
// import WorkHomeEight from "../HomeEight/WorkHomeEight.vue";
// import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import SidebarHomeOne from "../HomeOne/SidebarHomeOne.vue";
// import FunfactHomeThree from "../HomeThree/FunfactHomeThree.vue";
import ServicesHomeThree from "../HomeThree/ServicesHomeThree.vue";
// import NavItems from "../NavItems.vue";
import HeaderHomeEight from "../HomeEight/HeaderHomeEight.vue";
import FooterHomeEight from "../HomeEight/FooterHomeEight.vue";
export default {
  components: {
    SidebarHomeOne,
    // NavItems,
    ServicesHomeThree,
    // FunfactHomeThree,
    // WorkHomeEight,
    // TestimonialHomeEight,
    // SponserHomeEight,
    // FooterHomeOne,
    HeaderHomeEight,
    FooterHomeEight,
  },
  data() {
    return {
      sidebar: false,
      navs: [
        // {
        //   name: "home",
        //   childrens: [
        //     {
        //       name: "Home 1",
        //       path: "/",
        //     },
        //     {
        //       name: "Home 2",
        //       path: "/home-two",
        //     },
        //     {
        //       name: "Home 3",
        //       path: "/home-three",
        //     },
        //     {
        //       name: "Home 4",
        //       path: "/home-four",
        //     },
        //     {
        //       name: "Home 5",
        //       path: "/home-five",
        //     },
        //     {
        //       name: "Home 6",
        //       path: "/home-six",
        //     },
        //     {
        //       name: "Home 7",
        //       path: "/home-seven",
        //     },
        //     {
        //       name: "Home 8",
        //       path: "/home-eight",
        //     },
        //     {
        //       name: "Home Dark",
        //       path: "/home-dark",
        //     },
        //     {
        //       name: "Home Rtl",
        //       path: "/home-rtl",
        //     },
        //   ],
        // },
        // {
        //   name: "service",
        //   path: "/service-details",
        // },
        // {
        //   name: "pages",
        //   childrens: [
        //     {
        //       name: "About us",
        //       path: "/about-us",
        //     },
        //     {
        //       name: "About us 2",
        //       path: "/about-two",
        //     },
        //     {
        //       name: "Shops",
        //       path: "/shops",
        //     },
        //     {
        //       name: "Shop Details",
        //       path: "/shop-details",
        //     },
        //     {
        //       name: "Error",
        //       path: "/error",
        //     },
        //   ],
        // },
        // {
        //   name: "news",
        //   childrens: [
        //     {
        //       name: "News",
        //       path: "/news",
        //     },
        //     {
        //       name: "Single news",
        //       path: "/single-news",
        //     },
        //   ],
        // },
        // {
        //   name: "contact",
        //   path: "/contact",
        // },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>