<template>
      <section class="appie-footer-area appie-footer-about-area appie-footer-8-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-about-widget">
                        <div class="logo">
                            <a href="#"><img src="@/assets/images/anymoney-logo-32.png" alt=""></a>
                        </div>
                        <p>Most affordable, secure and friendly platform for all your remittances from the USA to Africa. Your first transaction is on us. Available on Web, Mobile & PC</p>
                        <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
                        <div class="social mt-30">
                            <ul>
                                <li><a href="https://www.facebook.com/people/AnyMoney/100076130652061/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                                <li><a href="https://twitter.com/anymoneyapp" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">Company</h4>
                      <ul>
                            <li><router-link to="/about-us">About Us</router-link></li>
                            <!-- <li><router-link to="/service-details">Service</router-link></li> -->
                            <!-- <li><a href="#">Case Studies</a></li> -->
                            <!-- <li><router-link to="/news">Blog</router-link></li> -->
                            <!-- <li><router-link to="/contact">Contact</router-link></li> -->
                            <li><a href="https://anymoney.tawk.help/" target="_blank">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">Support</h4>
                        <ul>
                            <!-- <li><a href="#">Community</a></li> -->
                            <li><a href="https://anymoney.tawk.help/" target="_blank">Resources</a></li>
                            <!-- <li><a href="https://anymoney.tawk.help/" target="_blank">Faqs</a></li> -->
                            <li><a href="https://machnetservices.com/golden-money-transfer/anymoney-llc/" target="_blank">Privacy Policy</a></li>
                            <li><a href="https://machnetservices.com/golden-money-transfer/license-and-complaint/" target="_blank">Licence & Complaint</a></li>
                            <!-- <li><a href="#">Careers</a></li> -->
                        </ul>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget-info">
                        <h4 class="title">Get In Touch</h4>
                        <ul>
                            <li><a href="mailto: contact@anymoney.io"><i class="fal fa-envelope"></i> contact@anymoney.io</a></li>
                            <li><a href="tel:+1(304)9070060"><i class="fal fa-phone"></i> +1 (304) 907 0060</a></li>
                            <li><a href="#"><i class="fal fa-map-marker-alt"></i> 265 N Bay Drive, Bullard TEXAS 75757</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-copyright d-flex align-items-center justify-content-between pt-35">
                        <div class="apps-download-btn">
                        <ul>
                            <li><a class="item-1" href="https://play.google.com/store/search?q=anymoney&c=apps" target="_blank"><i class="fab fa-google-play"></i> Android</a></li>
                            <li><a href="https://apps.apple.com/app/apple-store/id1659219019?pt=123927420&ct=website&mt=8" target="_blank"><i class="fab fa-apple"></i> iOS</a></li>
                            <li><a class="item-1" href="https://app.anymoney.co" target="_blank"><i class="fab fa-windows"></i> Web</a></li>
                        </ul>
                        </div>
                        <div class="copyright-text">
                            <p>Copyright © 2022 <b>AnyMoney</b>. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>